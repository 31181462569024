<template>
    <v-container>
        <v-card>
            <v-container>
                <s-toolbar label="Lista de Usuarios" dark color="#8e8f91"><s-text v-model="textSearch"></s-text></s-toolbar>
                <v-container style="padding: 0%;">
                    <v-data-table
                            :loading="isLoading"
                            item-key="NtpID"
                            dense
                            fixed-header=""
                            :items="itemsSearchFilter"
                            :headers="headersSearch"
                            :items-per-page="10"
                            @click:row="rowClickSearch"
                            v-model="selectedSearch"
                        >
                        <template v-slot:item.AsgActionAdd="{ item }">
                            <v-btn
                            color="success"
                            x-small
                            @click="AsgAdd(item)"
                            block
                            >
                            <v-icon small>mdi-whatsapp</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-container>

                <hr style="margin: 20px 0;">

                <v-row>
                    <v-col sm="9">
                        <s-textarea
                          v-model="message"
                          label="Escribe tu mensaje aquí"
                          :height="95"
                        ></s-textarea>
                    </v-col sm="2">
                    <v-col align-self="end">
                        <v-row>
                            <v-col>
                                <v-btn block color="success" :disabled="disabledBtnWhatsapp" @click="sendWhatsAppMessage">Enviar WhatsApp&nbsp;<i class="fab fa-whatsapp"></i></v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn block color="primary" :disabled="disabledBtnSMS" @click="sendSMSMessage">Enviar SMS&nbsp;<i class="fas fa-phone-alt"></i></v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <s-toolbar label="Lista de Usuarios Seleccionados" clear @clear="clearSearchSelected" dark color="#8e8f91" style="margin: 20px 0 0 0;"></s-toolbar>
                <v-container style="padding: 0%;">
                    <v-data-table
                            dense
                            fixed-header=""
                            :items="itemsSearchSelected"
                            :headers="headersSearchSelected"
                            :items-per-page="10"
                        >
                        <template v-slot:item.AsgActionRemove="{ item }">
                            <v-btn
                            color="error"
                            x-small
                            @click="AsgRemove(item)"
                            block
                            >
                            <v-icon small>mdi-whatsapp</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-container>
            </v-container>
        </v-card>
    </v-container>
</template>
  
<script>
import RecruitmentCall from '../../../services/HumanResource/RecruitmentCall';
import _sGenParam from "../../../services/General/ParameterService";

export default {
    data() {
        return {
            disabledBtnWhatsapp: false,
            disabledBtnSMS: false,

            urlWhatsApp1: "",
            urlWhatsApp2: "",
            urlSMS1: "",
            urlSMS2: "",
            numberWhatsAppAPI: "",
            numberSMSAPI: "",
            accountSIDSMS: "",
            tokenWhatsAppAPI: "",
            tokenSMSAPI: "",

            isLoading: true,
            textSearch: "",
            selectedSearch: [],
            itemsSearchFilter: [],
            recipientNumber: "",
            message: "",
            AsgActionAdd: null,
            AsgActionRemove: null,
            headersSearch: [
                {text: "Apellidos y Nombres"        		, value: "NtpFullName"},
                {text: "Numero de Celular"					, value: "PpnNumberPhone"},
                {text: "Numero de DNI"					, value: "PrsDocumentNumber"},
                {text: "Agregar"					        , value: "AsgActionAdd", sortable: false},
            ],
            headersSearchSelected: [
                {text: "Apellidos y Nombres"        		, value: "NtpFullName"},
                {text: "Numero de Celular"					, value: "PpnNumberPhone"},
                {text: "Numero de DNI"					, value: "PrsDocumentNumber"},
                {text: "Eliminar"					        , value: "AsgActionRemove", sortable: false},
            ],
            itemsSearch: [
            ],
            itemsSearchSelected: [],
        };
    },
    methods: {
        async sendWhatsAppMessage() {
            this.disabledBtnWhatsapp = true;

            for (let i = 0; i < this.itemsSearchSelected.length; i++) {
                const item = this.itemsSearchSelected[i];
                const PpnNumberPhone = item.PpnNumberPhone;

                await this.$fun.sendWhatsAppMessage(this.urlWhatsApp1, this.numberWhatsAppAPI, this.urlWhatsApp2, this.tokenWhatsAppAPI, this.message, PpnNumberPhone);
            }

            this.$fun.alert("Mensajes de WhatsApp Enviados", "success");
            this.disabledBtnWhatsapp = false;
        },
        async sendSMSMessage() {
            this.disabledBtnSMS = true;
            for (let i = 0; i < this.itemsSearchSelected.length; i++) {
                const item = this.itemsSearchSelected[i];
                const PpnNumberPhone = item.PpnNumberPhone;

                await this.$fun.sendSMSMessage(this.urlSMS1, this.numberSMSAPI, this.urlSMS2, this.tokenSMSAPI, this.message, PpnNumberPhone, this.accountSIDSMS);
            }

            this.$fun.alert("Mensajes de SMS Enviados", "success");
            this.disabledBtnSMS = false;
        },
        clearSearchSelected() {
            this.itemsSearchSelected = [];
            this.isLoading = true;
            this.searchPerson();
        },
        rowClickSearch: function(item, row) {
            if (item !== undefined) {
                if(item == this.selectedSearch[0]){
                    this.selectedSearch = [];
                }else{
                    this.selectedSearch = [item];
                }
            }
        },
        AsgAdd(item){
            this.itemsSearchSelected.push(item);

            this.itemsSearch.splice(this.itemsSearch.indexOf(item), 1);

            this.itemsSearchFilter = this.itemsSearch;
        },
        AsgRemove(item){
            this.itemsSearch.push(item);

            this.itemsSearchSelected.splice(this.itemsSearchSelected.indexOf(item), 1);

            this.itemsSearchFilter = this.itemsSearch;
        },
        searchPerson() {
            RecruitmentCall.listPerson(this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {
                        this.itemsSearch = r.data;
                        this.itemsSearchFilter = r.data;
                        this.isLoading = false;
                    }
                });
        },
    },
    watch: {
        textSearch(newVal) {
                this.itemsSearchFilter = this.itemsSearch.filter((item) =>
                    item.NtpFullName.toLowerCase().includes(newVal.toLowerCase()) ||
                    item.PpnNumberPhone.toLowerCase().includes(newVal.toLowerCase()) ||
                    item.PrsDocumentNumber.toLowerCase().includes(newVal.toLowerCase())
                );
            },
        },
    async created() {
        this.searchPerson();

        await _sGenParam.search({ PrmName: "urlWhatsApp1" },this.$fun.getUserID(),)
        .then(resp => {
            this.urlWhatsApp1 = resp.data.PrmValue;
        });

        await _sGenParam.search({ PrmName: "urlWhatsApp2" },this.$fun.getUserID(),)
        .then(resp => {
            this.urlWhatsApp2 = resp.data.PrmValue;
        });

        await _sGenParam.search({ PrmName: "urlSMS1" },this.$fun.getUserID(),)
        .then(resp => {
            this.urlSMS1 = resp.data.PrmValue;
        });

        await _sGenParam.search({ PrmName: "urlSMS2" },this.$fun.getUserID(),)
        .then(resp => {
            this.urlSMS2 = resp.data.PrmValue;
        });

        await _sGenParam.search({ PrmName: "numberWhatsAppAPI" },this.$fun.getUserID(),)
        .then(resp => {
            this.numberWhatsAppAPI = resp.data.PrmValue;
        });

        await _sGenParam.search({ PrmName: "numberSMSAPI" },this.$fun.getUserID(),)
        .then(resp => {
            this.numberSMSAPI = resp.data.PrmValue;
        });

        await _sGenParam.search({ PrmName: "accountSIDSMS" },this.$fun.getUserID(),)
        .then(resp => {
            this.accountSIDSMS = resp.data.PrmValue;
        });

        await _sGenParam.search({ PrmName: "tokenWhatsAppAPI" },this.$fun.getUserID(),)
        .then(resp => {
            this.tokenWhatsAppAPI = resp.data.PrmValue;
        });

        await _sGenParam.search({ PrmName: "tokenSMSAPI" },this.$fun.getUserID(),)
        .then(resp => {
            this.tokenSMSAPI = resp.data.PrmValue;
        });
    }
};
</script>