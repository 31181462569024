import Service from "../Service";
 
const resource = "HmnRecruitmentCall/";

export default {
    listPerson(requestID) {
        return Service.post(resource + "listPerson", {}, {
            params: { requestID: requestID },
        });
    },
};